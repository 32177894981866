import React from "react";
import type { SxProps } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Text } from "~/components/text";
import { IncompleteProfileGuard } from "./guards";
import type { ProfileCompletionStatus } from "./use-profile-completion-status";

export function IncompleteProfileWarning({
  action,
  sx,
}: {
  action?:
    | React.ReactNode
    | ((status: ProfileCompletionStatus) => React.ReactNode);
  sx?: SxProps;
}) {
  return (
    <IncompleteProfileGuard>
      {(status) => (
        <Alert variant="outlined" severity="warning" sx={sx}>
          <AlertTitle>Incomplete Profile</AlertTitle>
          <Text inherit>
            Your profile is incomplete. Without a complete profile, you will not
            be able to create extractions.
          </Text>
          {typeof action === "function" ? action(status) : action}
        </Alert>
      )}
    </IncompleteProfileGuard>
  );
}
