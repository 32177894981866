import type { UseQueryResult } from "@tanstack/react-query";
import type { MeDataResponse } from "~/dsm";
import { useCurrentDsmUser } from "~/dsm";
import { checkCompanyNotApplicable } from "./utils";

export interface ProfileCompletionStatus {
  complete: boolean;
  name: boolean;
  company: boolean;
}

export function useProfileCompletionStatus(): UseQueryResult<ProfileCompletionStatus> {
  return useCurrentDsmUser({
    select: getProfileCompletionStatus,
  });
}

function getProfileCompletionStatus({
  data,
}: MeDataResponse): ProfileCompletionStatus {
  if (data == null) {
    return {
      complete: false,
      name: false,
      company: false,
    };
  }

  const nameComplete = data.firstName != null && data.lastName != null;
  const companyComplete =
    data.company != null || checkCompanyNotApplicable(data);

  return {
    complete: nameComplete && companyComplete,
    name: nameComplete,
    company: companyComplete,
  };
}
