import type React from "react";
import { useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { createQueryClient } from "~/create-query-client";
import {
  APIKeyApi,
  DataStoreClientsContext,
  DigestionApi,
  GroupApi,
  IngestionApi,
  LabelApi,
  LogApi,
  LqsVersionProvider,
  ObjectStoreApi,
  RoleApi,
  TopicApi,
  UserApi,
  WorkflowApi,
} from "~/lqs";
import { createLqsSdkConfiguration, DATA_STORE_VERSION } from "./config";

export function DataStoreClientsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [clients] = useState(() => {
    const configuration = createLqsSdkConfiguration();

    return {
      apiKeyApi: new APIKeyApi(configuration),
      digestionApi: new DigestionApi(configuration),
      groupApi: new GroupApi(configuration),
      ingestionApi: new IngestionApi(configuration),
      labelApi: new LabelApi(configuration),
      logApi: new LogApi(configuration),
      objectStoreApi: new ObjectStoreApi(configuration),
      roleApi: new RoleApi(configuration),
      topicApi: new TopicApi(configuration),
      userApi: new UserApi(configuration),
      workflowApi: new WorkflowApi(configuration),
    };
  });

  const [queryClient] = useState(createQueryClient);

  return (
    <DataStoreClientsContext.Provider value={clients}>
      <QueryClientProvider client={queryClient}>
        <LqsVersionProvider version={DATA_STORE_VERSION}>
          {children}
        </LqsVersionProvider>
      </QueryClientProvider>
    </DataStoreClientsContext.Provider>
  );
}
