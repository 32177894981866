import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { IncompleteProfileWarning } from "./incomplete-profile-warning";
import { ProfileForm } from "./profile-form";

export function CompleteProfileDialog() {
  const [open, setOpen] = useState(false);

  const [preventClosing, setPreventClosing] = useState(false);

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    if (preventClosing) {
      return;
    }

    setOpen(false);
  }

  function handlePreventClosing(): void {
    setPreventClosing(true);
  }

  function handleAllowClosing(): void {
    setPreventClosing(false);
  }

  function handleUpdateComplete(): void {
    setOpen(false);
  }

  return (
    <>
      <IncompleteProfileWarning
        action={
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={handleOpen}
          >
            Complete Profile...
          </Button>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="complete-profile-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="complete-profile-dialog-title">
          Complete Your Profile
        </DialogTitle>
        <DialogContent>
          <ProfileForm
            inDialog
            onClose={handleClose}
            onLoadingStart={handlePreventClosing}
            onLoadingEnd={handleAllowClosing}
            onUpdateComplete={handleUpdateComplete}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
