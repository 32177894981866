import React from "react";
import { Skeleton } from "@mui/material";
import { useGroup } from "~/lqs";
import { usePlayerLog, usePlayerParams } from "~/player";

export function PublicPlayerHeaderTitle() {
  const { logId } = usePlayerParams();

  const logQuery = usePlayerLog();
  const logGroupQuery = useGroup(logQuery.data?.groupId ?? null, {
    select: (response) => response.data.name,
  });

  if (logId == null || logQuery.isError) {
    return "Player";
  } else if (logQuery.isLoading) {
    return (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );
  } else {
    let group;
    if (logGroupQuery.isLoading) {
      group = (
        <span style={{ fontSize: "0.85em" }}>
          (<Skeleton width="10ch" sx={{ display: "inline-block" }} />)
        </span>
      );
    } else if (logGroupQuery.isError) {
      group = null;
    } else {
      group = (
        <span style={{ fontSize: "0.85em" }}>({logGroupQuery.data})</span>
      );
    }

    return (
      <>
        Player - {logQuery.data.name} {group}
      </>
    );
  }
}
