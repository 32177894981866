import React from "react";
import { Container, Stack } from "@mui/material";
import { Navigate } from "react-router-dom";
import { Card } from "~/components/Card";
import { Text } from "~/components/text";
import { AuthenticationGuard } from "~/domain/auth";
import { IncompleteProfileWarning, ProfileForm } from "~/domain/profile";
import { DsmResourceFields, useCurrentDsmUser } from "~/dsm";
import * as Layout from "~/layout";
import { invariant } from "~/lib/invariant";
import { useLqsNavigator } from "~/paths";

export function ProfilePage() {
  const lqsNavigator = useLqsNavigator();

  return (
    <Layout.Page title="Profile">
      <Container fixed maxWidth="md">
        <AuthenticationGuard
          unauthenticatedFallback={
            <Navigate to={lqsNavigator.toDashboard()} replace />
          }
        >
          <AuthenticatedContent />
        </AuthenticationGuard>
      </Container>
    </Layout.Page>
  );
}

function AuthenticatedContent() {
  const profileQuery = useCurrentDsmUser({
    select({ data }) {
      invariant(data != null, "Expected user to be present");

      return data;
    },
  });

  return (
    <Stack direction="column" spacing={4}>
      <Card title="Personal Information">
        <IncompleteProfileWarning
          sx={{ mb: 2 }}
          action={(status) => (
            <>
              <Text inherit>
                To complete your profile, please provide the following:
              </Text>
              <ul>
                {!status.name && <li>First and last name</li>}
                {!status.company && <li>Company (if applicable)</li>}
              </ul>
            </>
          )}
        />
        <ProfileForm />
      </Card>
      <Card title="Account">
        <DsmResourceFields
          query={profileQuery}
          fields={[
            {
              accessor: "username",
              dataType: "text",
            },
          ]}
        />
      </Card>
    </Stack>
  );
}
