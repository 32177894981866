import React from "react";
import {
  Button,
  Container,
  Link,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Card } from "~/components/Card";
import { Loading } from "~/components/Loading";
import { renderQuery } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { AuthenticationGuard } from "~/domain/auth";
import { DigestionListItem } from "~/domain/digestions";
import { CompleteProfileDialog } from "~/domain/profile";
import * as Layout from "~/layout";
import { invariant } from "~/lib/invariant";
import type { Digestion } from "~/lqs";
import { useCurrentUser, useDigestions } from "~/lqs";
import {
  calculateProcessRefetchInterval,
  checkIsProcessDynamic,
} from "~/lqs/process";
import { useLqsNavigator } from "~/paths";
import { selectData } from "~/utils/queries";
import { useAuthDialogControls } from "./auth-dialog";

export function DigestionsPage() {
  return (
    <Layout.Page title="Your Extractions">
      <Container fixed maxWidth="md">
        <AuthenticationGuard
          unauthenticatedFallback={<UnauthenticatedContent />}
        >
          <AuthenticatedContent />
        </AuthenticationGuard>
      </Container>
    </Layout.Page>
  );
}

function UnauthenticatedContent() {
  const authDialogControls = useAuthDialogControls();

  return (
    <Card title="Sign in to see your extractions">
      <Typography gutterBottom>
        You must be signed in to your account to see your extractions.
      </Typography>
      <Typography gutterBottom>
        Once you sign in, any extractions you create in the Player will be
        available here to download.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={authDialogControls.open}
      >
        Sign In / Sign Up
      </Button>
    </Card>
  );
}

function AuthenticatedContent() {
  const lqsNavigator = useLqsNavigator();

  const currentUserQuery = useCurrentUser({
    select({ data }) {
      invariant(data != null, "Expected user to be present");

      return data;
    },
  });

  const digestionsQuery = useDigestions<Array<Digestion>>(
    {
      createdBy: currentUserQuery.data?.id,
      limit: 100,
      order: "created_at",
      sort: "desc",
      includeCount: false,
    },
    {
      enabled: currentUserQuery.isSuccess,
      select: selectData,
      refetchInterval(digestions) {
        if (digestions == null) {
          return false;
        }

        for (const digestion of digestions) {
          const calculatedInterval = calculateProcessRefetchInterval(digestion);
          if (typeof calculatedInterval === "number") {
            return calculatedInterval;
          }
        }

        return false;
      },
    },
  );

  return (
    <Card>
      {renderQuery(digestionsQuery, {
        loading: (
          <Loading type="circular">
            <Typography variant="h6" component="p">
              Loading your extractions
            </Typography>
          </Loading>
        ),
        error: <ErrorMessage>Unable to fetch your extractions</ErrorMessage>,
        success(digestions) {
          if (digestions.length === 0) {
            return (
              <>
                <CompleteProfileDialog />
                <Typography gutterBottom>
                  You haven't created any extractions yet.
                </Typography>
                <Typography>
                  To create an extraction, open a log from{" "}
                  <Link component={RouterLink} to={lqsNavigator.toDashboard()}>
                    the dashboard
                  </Link>{" "}
                  in the Player, then open the Extractions sidebar.
                </Typography>
              </>
            );
          }

          const someDigestionsAreDynamic = digestions.some(
            checkIsProcessDynamic,
          );

          return (
            <>
              <CompleteProfileDialog />
              <List
                disablePadding
                sx={{ px: 1 }}
                subheader={
                  someDigestionsAreDynamic && (
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{ lineHeight: "normal", mb: 1 }}
                    >
                      List will update until all extractions are complete
                    </ListSubheader>
                  )
                }
              >
                {digestions.map((digestion) => (
                  <DigestionListItem
                    key={digestion.id}
                    digestion={digestion}
                    size="large"
                  />
                ))}
              </List>
            </>
          );
        },
      })}
    </Card>
  );
}
