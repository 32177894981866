import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "~/css/fonts";
import "~/css/styles.css";
import { DocumentTitleSuffixProvider } from "~/components/document-title";
import {
  AuthenticationGuard,
  AuthenticationStatusProvider,
} from "~/domain/auth";
import { CompleteProfileBanner } from "~/domain/profile";
import { DsmProvider } from "~/dsm/provider";
import { DefaultErrorFallback } from "~/errors";
import { NavigationElementProvider } from "~/layout";
import { NotFoundPage } from "~/pages";
import { CommonQueryClientProvider } from "~/providers/CommonQueryClientProvider";
import ThemeProvider from "~/providers/ThemeProvider";
import {
  ColorSchemeControl,
  DateDisplayControl,
  GlobalSettingsControlsProvider,
  SettingsProvider,
} from "~/settings";
import { AuthDialogProvider } from "./auth-dialog";
import { createDsmSdkConfiguration } from "./config";
import { PublicDataStoreDashboardPage } from "./dashboard";
import { DataStoreClientsProvider } from "./data-store-clients-provider";
import { DigestionsPage } from "./digestions-page";
import { lqsRoutePaths, PublicLqsNavigatorProvider } from "./lqs-navigator";
import { PublicPlayerPage } from "./player";
import { ProfilePage } from "./profile-page";
import { PublicLqsNavigation } from "./public-lqs-navigation";

export function App() {
  return (
    <AuthenticationStatusProvider>
      <SettingsProvider defaultColorScheme="light">
        <GlobalSettingsControlsProvider
          controls={
            <>
              <ColorSchemeControl />
              <DateDisplayControl />
            </>
          }
        >
          <DocumentTitleSuffixProvider suffix="LogQS - Carnegie Robotics">
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
                  <DsmProvider
                    createApiConfiguration={createDsmSdkConfiguration}
                  >
                    <SnackbarProvider maxSnack={1}>
                      <CommonQueryClientProvider>
                        <DataStoreClientsProvider>
                          <Router>
                            <AuthDialogProvider>
                              <PublicLqsNavigatorProvider>
                                <NavigationElementProvider
                                  element={<PublicLqsNavigation />}
                                >
                                  <Routes>
                                    <Route
                                      element={
                                        <>
                                          <Outlet />
                                          <AuthenticationGuard>
                                            <CompleteProfileBanner />
                                          </AuthenticationGuard>
                                        </>
                                      }
                                    >
                                      <Route
                                        path={lqsRoutePaths.DATASTORE_DASHBOARD}
                                        element={
                                          <PublicDataStoreDashboardPage />
                                        }
                                      />
                                      <Route
                                        path={lqsRoutePaths.PLAYER}
                                        element={<PublicPlayerPage />}
                                      />
                                    </Route>
                                    <Route
                                      path={lqsRoutePaths.DIGESTION_TABLE}
                                      element={<DigestionsPage />}
                                    />
                                    <Route
                                      path={lqsRoutePaths.PROFILE}
                                      element={<ProfilePage />}
                                    />
                                    <Route
                                      path="*"
                                      element={<NotFoundPage />}
                                    />
                                  </Routes>
                                </NavigationElementProvider>
                              </PublicLqsNavigatorProvider>
                            </AuthDialogProvider>
                          </Router>
                        </DataStoreClientsProvider>
                      </CommonQueryClientProvider>
                    </SnackbarProvider>
                  </DsmProvider>
                </ErrorBoundary>
              </LocalizationProvider>
            </ThemeProvider>
          </DocumentTitleSuffixProvider>
        </GlobalSettingsControlsProvider>
      </SettingsProvider>
    </AuthenticationStatusProvider>
  );
}
