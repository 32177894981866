import type React from "react";
import { renderQuery } from "~/components/QueryRenderer";
import type { ProfileCompletionStatus } from "./use-profile-completion-status";
import { useProfileCompletionStatus } from "./use-profile-completion-status";

export function ProfileCompletionGuard({
  pending = null,
  error = null,
  incomplete = null,
  children = null,
}: {
  pending?: React.ReactNode;
  error?: React.ReactNode;
  incomplete?:
    | React.ReactNode
    | ((status: ProfileCompletionStatus) => React.ReactNode);
  children?: React.ReactNode;
}) {
  const profileCompletionStatusQuery = useProfileCompletionStatus();

  return renderQuery(profileCompletionStatusQuery, {
    loading: pending,
    error,
    success(profileCompletionStatus) {
      if (profileCompletionStatus.complete) {
        return children;
      } else {
        return typeof incomplete === "function"
          ? incomplete(profileCompletionStatus)
          : incomplete;
      }
    },
  });
}

export function IncompleteProfileGuard({
  children,
}: {
  children:
    | React.ReactNode
    | ((status: ProfileCompletionStatus) => React.ReactNode);
}) {
  return <ProfileCompletionGuard incomplete={children} />;
}
